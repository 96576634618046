import '/public/styles/googleMaps/googleMapStyles.css';

import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Loader } from '@googlemaps/js-api-loader';
import { Clinic } from '@interface/clinic';
import { poppins } from 'app/fonts';

const styles = {
  silver: [
    {
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }],
    },
    {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#f5f5f5' }],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#c9c9c9' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
  ],
};

const Map = ({ selectedClinic }: { selectedClinic: Clinic }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY || '',
      version: 'weekly',
    });

    let map: google.maps.Map;

    loader.load().then(() => {
      const lat = selectedClinic.lat;
      const lng = selectedClinic.long;
      const latLng = new google.maps.LatLng(lat, lng);

      if (mapRef.current) {
        map = new google.maps.Map(mapRef.current, {
          center: latLng,
          zoom: isMobile ? 15 : 17,
          disableDefaultUI: true,
        });

        map.setOptions({ styles: styles['silver'] });

        const customIconUrl = '/images/customMarker.svg';

        new google.maps.Marker({
          position: latLng,
          map,
          icon: customIconUrl,
        });

        class CustomPopupOverlay extends google.maps.OverlayView {
          position: google.maps.LatLng;
          content: string;
          containerDiv: HTMLDivElement;

          constructor(position: google.maps.LatLng, content: string) {
            super();
            this.position = position;
            this.content = content;

            this.containerDiv = document.createElement('div');
            this.containerDiv.innerHTML = content;
            this.containerDiv.classList.add('clinicCustomPopUp');
            this.containerDiv.style.position = 'absolute';
            this.containerDiv.style.transform = 'translate(-50%, -100%)';
          }

          onAdd() {
            const panes = this.getPanes();
            if (panes) {
              panes.floatPane.appendChild(this.containerDiv);
            }
          }

          draw() {
            const overlayProjection = this.getProjection();
            if (overlayProjection) {
              const position = overlayProjection.fromLatLngToDivPixel(
                this.position
              );
              if (position) {
                this.containerDiv.style.left = position.x + 'px';
                this.containerDiv.style.top = position.y + 'px';
              }
            }
          }

          onRemove() {
            if (this.containerDiv.parentNode) {
              this.containerDiv.parentNode.removeChild(this.containerDiv);
            }
          }
        }

        const popupContent = `<div
              id="content"
              class="${poppins.className} text-sm text-hg-black -translate-y-16 bg-white p-4 rounded-2xl shadow-lg w-60"
            >
              <p class="text-lg font-semibold mb-2">${selectedClinic.city}</p>
              <p class="text-xs mb-2">${selectedClinic.address}</p>
              <a class="text-hg-secondary text-xs" href="https://wa.me/34682417208">
                Más info
              </a>
            </div>`;

        const customPopup = new CustomPopupOverlay(latLng, popupContent);
        customPopup.setMap(map);

        google.maps.event.addListenerOnce(map, 'idle', () => {
          map.panBy(0, -70);
        });
      }
    });

    return () => {
      if (map) {
        google.maps.event.clearInstanceListeners(map);
      }
    };
  }, [selectedClinic]);

  return (
    <div
      ref={mapRef}
      style={{
        width: '100%',
        position: 'absolute',
        inset: '0',
      }}
    />
  );
};

export default Map;
