'use client';

import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Clinic } from '@interface/clinic';
import CheckHydration from '@utils/CheckHydration';
import AnimateOnViewport from 'app/(web)/components/common/AnimateOnViewport';
import { SvgAngle } from 'app/icons/IconsDs';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'designSystem/Accordion/Accordion';
import { Flex } from 'designSystem/Layouts/Layouts';
import { Text } from 'designSystem/Texts/Texts';
import { isEmpty } from 'lodash';

import CustomMap from './CustomMap';
import FullScreenLoading from './FullScreenLayout';

export default function ClinicsSelector({ clinics }: { clinics: Clinic[] }) {
  const [selectedAccordion, setSelectedAccordion] = useState<string>('none');
  const [selectedClinic, setSelectedClinic] = useState<Clinic>();
  const [mapHeight, setMapHeight] = useState(0);
  const [googleMapAddress, setGoogleMapAddress] = useState('');

  useEffect(() => {
    if (!isMobile && clinics.length > 0) setSelectedClinic(clinics[0]);
  }, [clinics]);

  useEffect(() => {
    if (!isEmpty(selectedClinic)) {
      const formattedAddress = selectedClinic.address.replace(/ /g, '+');
      const formattedCity = selectedClinic.city.replace(/ /g, '+');

      setGoogleMapAddress(`${formattedAddress},${formattedCity}`);
    }
  }, [selectedClinic]);

  useEffect(() => {
    const mapLayer = document.querySelector('#mapLayer');

    if (mapLayer) {
      const mapLayerElement = mapLayer as HTMLElement;

      console.log(mapLayerElement.offsetHeight);
      setMapHeight(mapLayerElement.offsetHeight);
    }
  }, [googleMapAddress]);

  const sortedClinics = clinics.sort((a, b) => {
    if (a.autonomousRegion < b.autonomousRegion) {
      return -1;
    }
    if (a.autonomousRegion > b.autonomousRegion) {
      return 1;
    }
    return 0;
  });

  return (
    <CheckHydration>
      {isMobile && (
        <Accordion
          className={`w-full flex flex-col gap-4`}
          value={selectedAccordion}
        >
          {clinics &&
            sortedClinics.map((clinic, index) => (
              <AccordionItem
                key={index}
                value={index.toString()}
                className={`w-full rounded-2xl overflow-hidden
                ${
                  selectedAccordion === index.toString()
                    ? 'bg-hg-secondary100 border border-hg-secondary300'
                    : 'bg-derma-secondary300 border border-transparent'
                }`}
              >
                <AccordionTrigger className="w-full">
                  <Flex
                    className={`py-3 px-4 w-full rounded-t-2xl text-sm ${
                      selectedAccordion === index.toString()
                        ? 'bg-hg-secondary300'
                        : 'bg-hg-cream'
                    } `}
                    id="pw-clinicsModule-autonomousRegion"
                  >
                    {clinic.autonomousRegion}
                  </Flex>
                  <AnimateOnViewport>
                    <div
                      id={'tmevent_clinics_module'}
                      key="clinic.city"
                      onClick={() => {
                        if (index.toString() === selectedAccordion) {
                          setSelectedAccordion('none');
                        } else {
                          setSelectedAccordion(index.toString());
                        }

                        setSelectedClinic(clinics[index]);
                      }}
                    >
                      <Flex
                        layout="row-center"
                        className="w-full text-xs transition-all justify-between cursor-pointer p-3"
                      >
                        <Flex layout="col-left">
                          <Text size="lg" className="font-semibold mb-2">
                            {clinic.city}
                          </Text>
                          <address className="text-sm not-italic text-left">
                            {clinic?.address},{' '}
                            {clinic?.district ? `${clinic?.district}, ` : ''}{' '}
                            {clinic?.zipCode} {clinic?.province}
                            <br />
                            {clinic?.addressExtraInfo}
                          </address>
                        </Flex>

                        <SvgAngle
                          height={24}
                          width={24}
                          className="rotate-90 md:rotate-0"
                        />
                      </Flex>
                    </div>
                  </AnimateOnViewport>
                </AccordionTrigger>
                <AccordionContent className="bg-hg-secondary100 overflow-hidden w-full transition-all data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp relative">
                  <FullScreenLoading />
                  <div className="overflow-hidden max-w-full w-full h-[300px] relative z-10">
                    {selectedClinic && (
                      <CustomMap selectedClinic={selectedClinic} />
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
        </Accordion>
      )}

      {!isMobile && (
        <>
          <div className="flex w-1/2 relative">
            <div className="w-full mr-24 h-[460px] pr-4 bottom-0 overflow-y-scroll">
              <Flex layout="col-left" className="gap-4 w-full pb-4">
                {sortedClinics.map((clinic, index) => (
                  <Flex
                    id={'tmevent_clinics_module'}
                    layout="col-left"
                    className={`transition-all w-full justify-between cursor-pointer rounded-2xl ${
                      selectedClinic && selectedClinic.city === clinic.city
                        ? 'border border-hg-secondary300'
                        : 'border border-transparent'
                    } `}
                    key={clinic.city}
                    onClick={() => setSelectedClinic(clinics[index])}
                  >
                    <div
                      id="pw-clinicsModule-autonomousRegion"
                      className={`py-3 px-4 w-full rounded-t-2xl text-sm ${
                        selectedClinic && selectedClinic.city === clinic.city
                          ? 'bg-hg-secondary300'
                          : 'bg-hg-cream'
                      } `}
                    >
                      {clinic.autonomousRegion}
                    </div>

                    <Flex
                      layout="row-left"
                      className={`w-full py-5 px-4 rounded-b-2xl ${
                        selectedClinic && selectedClinic.city === clinic.city
                          ? 'bg-hg-secondary100'
                          : 'bg-hg-cream300'
                      } `}
                    >
                      <Flex layout="col-left" className="w-full">
                        <Text size="lg" className="font-semibold mb-2">
                          {clinic.city}
                        </Text>
                        <address className="text-xs not-italic">
                          {clinic?.address},{' '}
                          {clinic?.district ? `${clinic?.district}, ` : ''}{' '}
                          {clinic?.zipCode} {clinic?.province}
                          <br />
                          {clinic?.addressExtraInfo}
                        </address>
                      </Flex>
                      <SvgAngle height={24} width={24} />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </div>
          </div>
          <div
            id="mapLayer"
            className="absolute top-0 bottom-0 right-0 left-1/2"
          >
            <FullScreenLoading />
            <div
              className="overflow-hidden max-w-full w-full relative z-10"
              style={{ height: `${mapHeight}px` }}
            >
              {selectedClinic && <CustomMap selectedClinic={selectedClinic} />}
            </div>
          </div>
        </>
      )}
    </CheckHydration>
  );
}
