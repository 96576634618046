'use client';

import { useEffect } from 'react';
import { ConversionEventBody } from '@interface/facebookEvents';
import { useFacebookEvents } from 'app/hooks/useFacebookEvents';

export default function ContentViewEvent() {
  const { viewContentData, sendConversionEvent } = useFacebookEvents();

  useEffect(() => {
    if (viewContentData) {
      sendConversionEvent(viewContentData as ConversionEventBody);
    }
  }, [viewContentData]);

  return null;
}
