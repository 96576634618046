'use client';

import { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { ConversionEventBody } from '@interface/facebookEvents';
import { usePathname } from 'next/navigation';

export const useFacebookEvents = () => {
  const pathname = usePathname();

  const [ip, setIP] = useState<string | null>(null);
  const [fbc, setFbc] = useState<string | null>(null);
  const [fbp, setFbp] = useState<string | null>(null);
  const [viewContentData, setViewContentData] = useState<
    ConversionEventBody | undefined
  >(undefined);

  const fetchIP = async () => {
    try {
      const response = await fetch('/api/ip');
      if (!response.ok) {
        throw new Error('Failed to fetch IP');
      }
      const data = await response.json();
      setIP(data.ip);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchIP();

    const getCookieValue = (name: string) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts?.pop()?.split(';').shift();
    };

    setFbc(getCookieValue('_fbc') || null);
    setFbp(getCookieValue('_fbp') || null);
  }, []);

  useEffect(() => {
    if (ip) {
      setViewContentData({
        data: [
          {
            event_name: 'ViewContent',
            event_time: Math.floor(Date.now() / 1000),
            event_source_url: pathname,
            action_source: 'website',
            user_data: {
              client_ip_address: ip,
              client_user_agent: window.navigator.userAgent,
            },
          },
        ],
      });
    }
  }, [ip]);

  async function sendConversionEvent(body: ConversionEventBody) {
    const API_VERSION = 'v21.0';
    const PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;
    const TOKEN = process.env.NEXT_PUBLIC_FB_ACCESS_TOKEN;

    const url = `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${TOKEN}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        Bugsnag.notify('sendConversionEvent - Network response was not ok');
      }

      const result = await response.json();
      console.log('Success:', result);
    } catch (error) {
      Bugsnag.notify('Error sending conversion event', error as any);
    }
  }

  return {
    sendConversionEvent,
    viewContentData,
    fbc,
    fbp,
  };
};
